import { Directive, Input, TemplateRef } from "@angular/core";

@Directive({
  //eslint-disable-next-line
  selector: "ng-template[name]",
})
export class TemplateNameDirective {
  @Input({ required: true }) name!: string;

  constructor(public template: TemplateRef<any>) {}
}
