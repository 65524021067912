import { AplicationHttpClientModule } from "@core/config/application-http-client/application-http-client.module";

import { TranslateModule } from "@ngx-translate/core";

import { ToastModule } from "primeng/toast";

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormGroupDirective, ReactiveFormsModule } from "@angular/forms";

import { AutoConfigureLabelDirective } from "./directives/auto-configure-label.directive";
import { AutoValidatorDirective } from "./directives/auto-validator.directive";
import { DateMaskDirective } from "./directives/date-mask.directive";
import { FixNgxMaskDirective } from "./directives/fix-ngx-mask.directive";
import { FormArrayValidatorDirective } from "./directives/form-array-validator.directive";
import { FormValidatorDirective } from "./directives/form-validator.directive";
import { TemplateNameDirective } from "./directives/template-name.directive";
import { CpfPipe } from "./pipes/cpf-pipe.pipe";
import { PascalCasePipe } from "./pipes/pascal-case.pipe";
import { RadioAnswerPipe } from "./pipes/radio-answer.pipe";
import { ReplacePipe } from "./pipes/replace.pipe";
import { SafePipe } from "./pipes/safe.pipe";
import { TextQuestionPipe } from "./pipes/text-question.pipe";

const exportedModules = [
  AplicationHttpClientModule,
  ReactiveFormsModule,
  CommonModule,
  ToastModule,
  TranslateModule,
];
const exportedDirectives = [
  AutoValidatorDirective,
  FormValidatorDirective,
  DateMaskDirective,
  FixNgxMaskDirective,
  AutoConfigureLabelDirective,
  FormArrayValidatorDirective,
  TemplateNameDirective,
];

const exportedPipes = [
  PascalCasePipe,
  TextQuestionPipe,
  RadioAnswerPipe,
  CpfPipe,
  SafePipe,
  ReplacePipe,
];

@NgModule({
  imports: [
    AplicationHttpClientModule,
    ReactiveFormsModule,
    CommonModule,
    ToastModule,
  ],
  declarations: [
    AutoConfigureLabelDirective,
    AutoValidatorDirective,
    FormArrayValidatorDirective,
    FormValidatorDirective,
    DateMaskDirective,
    FixNgxMaskDirective,
    TemplateNameDirective,
    ...exportedPipes,
  ],
  providers: [FormGroupDirective],
  exports: [...exportedModules, ...exportedDirectives, ...exportedPipes],
})
export class SharedModule {}
