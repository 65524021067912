import { Directive, ElementRef, Input, OnInit, Renderer2 } from "@angular/core";

@Directive({
  selector: "label[appAutoConfigure]",
})
export class AutoConfigureLabelDirective implements OnInit {
  @Input() useRequiredValidator = true;
  @Input() icon?: string;
  @Input() configureLabel = true;

  @Input() useMargin = true;

  @Input() bold = false;

  @Input() fontSize?: number;

  @Input() uniqueKey?: string;

  private asteriskSpan?: HTMLSpanElement;

  constructor(
    private el: ElementRef<HTMLLabelElement>,
    private renderer: Renderer2,
  ) {}

  configureLabelElement(): void {
    if (!this.configureLabel) return;
    this.renderer.setStyle(this.el.nativeElement, "display", "block");

    if (this.useMargin)
      this.renderer.setStyle(this.el.nativeElement, "margin-left", "10px");

    if (this.bold)
      this.renderer.setStyle(this.el.nativeElement, "font-weight", "700");

    if (this.fontSize)
      this.renderer.setStyle(
        this.el.nativeElement,
        "font-size",
        `${this.fontSize}px`,
      );

    this.renderer.setStyle(this.el.nativeElement, "padding-bottom", "4px");
    this.renderer.setStyle(this.el.nativeElement, "line-height", "18px");
  }

  setRequired(): void {
    if (!this.asteriskSpan) {
      this.asteriskSpan = this.renderer.createElement(
        "span",
      ) as HTMLSpanElement;
      const asteriskText = this.renderer.createText("*") as HTMLElement;
      this.renderer.appendChild(this.asteriskSpan, asteriskText);
      this.renderer.addClass(this.asteriskSpan, "p-error");
    }
    this.renderer.appendChild(this.el.nativeElement, this.asteriskSpan);
  }

  removeRequired(): void {
    if (!this.asteriskSpan) return;
    this.renderer.removeChild(this.el.nativeElement, this.asteriskSpan);
  }

  ngOnInit(): void {
    this.configureLabelElement();
  }
}
